<template>
  <div id="news-letter" class="rounded border p-4 bg-white">
    <div
        v-if="pending"
        id="pending"
        class="d-flex flex-column justify-content-center align-items-center align-content-center"
    >
      <h1 class="text-danger">
        LOADING
      </h1>
      <b-spinner
          variant="danger"
          size="lg"
      />
    </div>
    <b-form
        v-else
        v-on:submit.prevent="save"
    >
      <div class="d-flex flex-column">
        <div v-if="info.regDate" class="text-right">
          <div>
            <b-badge>
              {{ new Date(info.regDate).toLocaleString() }}
            </b-badge>
          </div>
          <div>
            <b-badge>
              {{ info.regUserId }}
            </b-badge>
          </div>
        </div>
        <div class="text-left">

          <b-form-group
              label="노출 여부"
              label-size="sm"
          >
            <b-form-checkbox
                v-model="info.use"
            >
              {{ info.use ? '노출' : '숨김' }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
              label="제목"
              description="제목을 입력해주세요"
              label-size="sm"
          >
            <b-form-input
                size="sm"
                v-model="info.title"
            />
          </b-form-group>
        </div>
        <div class="editor p-1">
          <editor
              :api-key="API_KEY"
              :init="EDITOR_OPTIONS"
              v-model="info.content"
          />
        </div>
        <hr/>
      </div>
      <div>
        <b-button
            type="submit"
            size="sm"
            squared
            block
        >
          <font-awesome-icon
              icon="save"
          />
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue'
  export default {
    name: "faqDetail",
    components: {
      Editor,
      // EditorMenuBar
    },
    data: () => ({
      pending: false,
      info: {
        _id: null,
        title: null,
        content: null,
        regDate: null,
        regUser: null,
        regUserId: null,
        use: true,
      },
      editor: null,
    }),
    created () {
      (async () => {
        const {_id} = this.$route.params
        if (_id) {
          this.info._id = _id
          await this.load()
        }
      })()
    },
    mounted () {

    },
    beforeDestroy () {
      if (this.editor) this.editor.destroy()
    },
    methods: {
      async load() {
        this.pending = true
        try {
          const {data} = await this.axios({
            url: `/support/faq/detail/${this.info._id}`,
          })
          const {result, title, content, ord, use, regDate, regUserId, regUser, _id} = data
          if (result) this.info = {content, title, regDate, regUser, regUserId, _id, use}
        } catch (e) {
        }
        this.pending = false
      },

      async save () {
        if (!confirm(`${this.info._id ? '수정' : '등록'}하시겠습니까?`)) return
        this.pending = true
        try {
          const { data } = await this.axios({
            url: this.info._id ? `/support/faq/save/${this.info._id}` : `/support/faq/save`,
            method: this.info._id ? 'PATCH' : 'POST',
            data: {
              info: this.info
            }
          })
          const {result, error} = data
          if (result) {
            await this.$router.push({
              path: `/faq`
            })
          } else {
            this.$bvToast.toast(`${error}`, {
              title: 'ERROR',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: false
            })
          }
        } catch (e) {
        }
        this.pending = false
      }
    }
  }
</script>

<style scoped>
  #news-letter {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
  }
</style>
